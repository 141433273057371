//components/ProjectManagement.js
import React, { useState } from 'react';
import { DropdownButton, Dropdown, Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { createProject, deleteProject } from '../utils/api';
import { dropdownButtonStyle } from './styles'; // Corrected import

const ProjectManagement = ({
  projects,
  setProjects,
  currentProjectId,
  setCurrentProjectId,
  setCurrentProjectName,
  token,
  setGroupAddresses,
  setDynamicCards
}) => {
  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId, token);
      setProjects((prev) => prev.filter((project) => project.id !== projectId));
      if (currentProjectId === projectId) {
        setCurrentProjectId(null);
        setCurrentProjectName('');
        setGroupAddresses({ id: uuidv4(), addresses: [] });
        setDynamicCards([]);
      }
      toast.success('Project deleted successfully');
    } catch (error) {
      toast.error('Error deleting project');
    }
  };

  const handleProjectSelect = (projectId) => {
    const project = projects.find((p) => p.id === projectId);
    setCurrentProjectId(projectId);
    setCurrentProjectName(project.name);
    console.log(`Project selected: ${project.name}`);
  };

  return (
    <>
      <DropdownButton id="dropdown-project-button" title="Select Project" css={dropdownButtonStyle}>
        {projects.map((project) => (
          <Dropdown.Item key={project.id} onClick={() => handleProjectSelect(project.id)}>
            {project.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
};

export default ProjectManagement;
