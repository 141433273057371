import React, { createContext, useContext } from 'react';
import { DTP_OPTIONS } from '../constants/dtpOptions';

const DtpOptionsContext = createContext(DTP_OPTIONS);

export const useDtpOptions = () => useContext(DtpOptionsContext);

export const DtpOptionsProvider = ({ children }) => {
  return (
    <DtpOptionsContext.Provider value={DTP_OPTIONS}>
      {children}
    </DtpOptionsContext.Provider>
  );
};