/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useDrop } from 'react-dnd';

const dragDropContainerStyles = {
  container: css`
    padding: 10px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    transition: background-color 0.3s;
  `,
  active: css`
    background-color: #e0f7fa;
    border-color: #007bff;
  `,
};

const DragDropContainer = ({ onDrop, children, containerId, containerType }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'groupAddress',
    drop: (item, monitor) => {
      const sourceId = monitor.getItem().sourceId || containerId;
      onDrop(item, sourceId, containerId, containerType);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} css={[dragDropContainerStyles.container, isOver && dragDropContainerStyles.active]}>
      {children}
    </div>
  );
};

export default DragDropContainer;
