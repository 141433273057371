/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { Form, Button, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaTrashAlt, FaEdit, FaInfoCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { css } from '@emotion/react';
import GroupAddressItem from './GroupAddressItem';
import { useCardOptions } from '../contexts/CardOptionsContext';
import { useCardLimits } from '../contexts/CardLimitsContext';
import { useDtpOptions } from '../contexts/DtpOptionsContext';
import { cardStyle, listGroupItemStyle, formGroupStyle } from './styles';

const BasicLightCard = ({
  onDrop,
  configurations,
  sourceId,
  updateDoneCardsCount,
  onRemove,
  handleCardDoneChange,
  isDone,
  updateConfigurations,
  moveEntityToGroupAddresses,
  GROUP_ADDRESS_ID,
  cardName,
  updateCardName,
  cardType,
  setGroupAddresses 
}) => {
  const cardOptions = useCardOptions();
  const dtpOptions = useDtpOptions();
  
  const basicLightOptions = cardOptions.basicLight || [];
  const allDtpOptions = dtpOptions.basicLight || [];

  console.log('DTP Options for Basic Light:', allDtpOptions);

  const [localConfigurations, setLocalConfigurations] = useState(configurations);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(cardName);
  const [isEditingName, setIsEditingName] = useState(false);
  const nameInputRef = useRef(null);
  const [previousName, setPreviousName] = useState(cardName);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [optionValues, setOptionValues] = useState(() => {
    const initialValues = {};
    basicLightOptions.forEach(option => {
      initialValues[option] = '';
    });
    return initialValues;
  });

  useEffect(() => {
    setLocalConfigurations(configurations);
  }, [configurations]);

  const handleDrop = useCallback((item, targetDtp) => {
    if (isDone) return;
    
    setLocalConfigurations(prev => {
      const isOccupied = prev.some(config => config.dtp === targetDtp);
      if (isOccupied) return prev;

      const newConfig = { ...item, dtp: targetDtp };
      const updatedConfigurations = [...prev.filter(config => config.id !== item.id), newConfig];
      updateConfigurations(sourceId, updatedConfigurations, optionValues);
      return updatedConfigurations;
    });

    setGroupAddresses((prev) => ({
      ...prev,
      addresses: prev.addresses.filter(address => address.id !== item.id)
    }));

    onDrop(item, GROUP_ADDRESS_ID);
  }, [isDone, sourceId, optionValues, updateConfigurations, setGroupAddresses, onDrop, GROUP_ADDRESS_ID]);

  const handleRemoveEntity = useCallback((entityId) => {
    if (isDone) return;

    setLocalConfigurations(prev => {
      const entityToRemove = prev.find(config => config.id === entityId);
      const updated = prev.filter(config => config.id !== entityId);
      
      updateConfigurations(sourceId, updated, optionValues);
      
      if (entityToRemove) {
        moveEntityToGroupAddresses(entityToRemove);
      }
      
      return updated;
    });
  }, [isDone, sourceId, optionValues, updateConfigurations, moveEntityToGroupAddresses]);

  const handleDoneChange = useCallback(() => {
    const newDoneStatus = !isDone;
    handleCardDoneChange(newDoneStatus);
    setIsCollapsed(newDoneStatus);
  }, [isDone, handleCardDoneChange]);

  const handleRemoveCard = () => {
    setShowModal(true);
  };

  const confirmRemoveCard = () => {
    onRemove(sourceId);
    setShowModal(false);
  };

  const cancelRemoveCard = () => {
    setShowModal(false);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(newName) && newName.length <= 20) {
      setName(newName);
    }
  };

  const toggleEditingName = () => {
    if (isEditingName) {
      if (name.length < 3) {
        setName(previousName);
      } else {
        setPreviousName(name);
        updateCardName(sourceId, name);
      }
    } else {
      setPreviousName(name);
    }
    setIsEditingName((prev) => !prev);
  };

  useEffect(() => {
    if (isEditingName && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isEditingName]);

  const handleOptionValueChange = (label, value) => {
    setOptionValues(prev => ({ ...prev, [label]: value }));
  };

  const handleOptionValueBlur = () => {
    updateConfigurations(sourceId, localConfigurations, optionValues);
  };

  const isDoneDisabled = () => {
    if (name.length < 3) return true;
    if (localConfigurations.length === 0) return true;
    const mandatoryFilled = localConfigurations.some(config => config.dtp === allDtpOptions[0]?.name);
    return !mandatoryFilled;
  };

  const dropAreaStyle = css`
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
  `;

  const dropAreaHoverStyle = css`
    background-color: #e0f7fa;
    border-color: #007bff;
  `;

  const mandatoryEmptyStyle = css`
    border-color: #dc3545;
  `;

  const entityStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f1f3f5;
    }
  `;

  const DropArea = ({ dtpOption, isMandatory, index }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'groupAddress',
      drop: (item) => handleDrop(item, dtpOption.name),
      canDrop: () => !localConfigurations.some(config => config.dtp === dtpOption.name),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }), [localConfigurations, handleDrop]);

    const associatedEntity = localConfigurations.find(config => config.dtp === dtpOption.name);

    return (
      <div
        ref={drop}
        css={[
          dropAreaStyle,
          isMandatory && !associatedEntity && mandatoryEmptyStyle,
          isOver && dropAreaHoverStyle,
          css`
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: ${isOver ? 'rgba(0, 123, 255, 0.1)' : 'transparent'};
              pointer-events: none;
              transition: background-color 0.3s ease;
            }
          `
        ]}
      >
        <strong>{dtpOption.label} ({isMandatory ? 'Mandatory' : 'Optional'}):</strong>
        {associatedEntity ? (
          <div css={entityStyle}>
            <span>{associatedEntity.name} ({associatedEntity.address})</span>
            <Button
              variant="link"
              size="sm"
              className="text-danger p-0 ml-2"
              onClick={() => handleRemoveEntity(associatedEntity.id)}
              disabled={isDone}
              css={css`font-size: 1.2rem; line-height: 1;`}
            >
              <FaTrashAlt />
            </Button>
          </div>
        ) : (
          <em>Drop an entity here</em>
        )}
      </div>
    );
  };

  return (
    <>
      <div css={[cardStyle, isDone && css`background-color: #28a745;`]}>
        <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;`}>
          {isEditingName ? (
            <Form.Control 
              type="text" 
              placeholder="Enter card name" 
              value={name} 
              onChange={handleNameChange}
              ref={nameInputRef}
              onBlur={toggleEditingName}
              autoFocus
              css={css`
                ${name.length < 3 ? 'box-shadow: 0 0 5px red;' : 'box-shadow: 0 0 5px blue;'}
              `}
            />
          ) : (
            <h5>{name.length > 0 ? name : <span css={css`color: red;`}>Nume Card</span>}</h5>
          )}
          <div>
            <Button 
              variant="link" 
              size="sm" 
              className="text-secondary p-0 ml-2" 
              onClick={toggleEditingName}
              css={css`font-size: 1.2rem; line-height: 1;`}
              disabled={isDone}
            >
              <FaEdit />
            </Button>
            {isDone && (
              <Button
                variant="link"
                size="sm"
                className="text-secondary p-0 ml-2"
                onClick={() => setIsCollapsed(!isCollapsed)}
                css={css`font-size: 1.2rem; line-height: 1;`}
              >
                {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </Button>
            )}
          </div>
        </div>
        <div css={css`margin-bottom: 1rem;`}>
          <strong>Card Type: </strong>{cardType}
        </div>
        
        {!isCollapsed && (
          <>
            {Array.isArray(allDtpOptions) && allDtpOptions.map((option, index) => (
              <DropArea 
                key={`${option.name}-${index}`}
                dtpOption={option} 
                isMandatory={index === 0}
                index={index}
              />
            ))}
            
            {basicLightOptions.map((option, idx) => (
              <Form.Group key={idx} controlId={`option-${idx}`} css={formGroupStyle}>
                <Form.Label css={css`margin-right: 0.5rem; margin-bottom: 0; width: 30%;`}>{option}</Form.Label>
                <div css={css`display: flex; align-items: center; flex-grow: 1;`}>
                  <Form.Control
                    type="text"
                    value={optionValues[option] || ""}
                    onChange={(e) => handleOptionValueChange(option, e.target.value)}
                    onBlur={handleOptionValueBlur}
                    disabled={isDone}
                    css={css`flex-grow: 1;`}
                  />
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>{`Enter ${option.toLowerCase()} for the basic light`}</Tooltip>}
                  >
                    <FaInfoCircle css={css`margin-left: 5px;`} />
                  </OverlayTrigger>
                </div>
              </Form.Group>
            ))}
            
            <Button variant="danger" onClick={handleRemoveCard} css={css`margin-top: 0.5rem;`}>
              Remove
            </Button>
          </>
        )}
        
        <div css={css`display: flex; align-items: center; margin-top: 1rem;`}>
          <Form.Check
            type="checkbox"
            label="Done"
            checked={isDone}
            disabled={isDoneDisabled()}
            onChange={handleDoneChange}
            css={css`margin-right: 1rem;`}
          />
        </div>
      </div>

      <Modal show={showModal} onHide={cancelRemoveCard}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this basic light card? The entities will be moved back to the group addresses.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelRemoveCard}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmRemoveCard}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BasicLightCard;