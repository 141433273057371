// src/theme.js
const theme = {
    colors: {
      primary: '#007bff',
      secondary: '#6c757d',
      success: '#28a745',
      danger: '#dc3545',
      warning: '#ffc107',
      info: '#17a2b8',
      light: '#f8f9fa',
      dark: '#343a40',
      background: '#ddd',
      white: '#fff',
      gray: '#6c757d',
      black: '#000',
    },
    fonts: {
      main: "'Roboto', sans-serif",
    },
    spacing: {
      small: '8px',
      medium: '16px',
      large: '24px',
      xlarge: '32px',
    },
  };
  
export default theme;
  