/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { modalHeaderStyle, modalContentStyle, modalFooterStyle, formStyle } from './styles';

const ProjectSettings = ({ show, onHide, projectName, projectHost, onSave }) => {
  const [name, setName] = useState(projectName);
  const [host, setHost] = useState(projectHost);
  const [hostError, setHostError] = useState('');

  useEffect(() => {
    console.log('ProjectSettings initialized with name:', projectName, 'and host:', projectHost);
    setName(projectName);
    setHost(projectHost);
  }, [projectName, projectHost]);

  const handleSave = () => {
    const isValidHost = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(host);
    if (isValidHost) {
      setHostError('');
      console.log('Saving project settings with name:', name, 'and host:', host);
      onSave(name, host);
    } else {
      setHostError('Please enter a valid IP address.');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton css={modalHeaderStyle}>
        <Modal.Title>Project Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body css={modalContentStyle}>
        <Form css={formStyle}>
          <Form.Group>
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Host</Form.Label>
            <Form.Control
              type="text"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              isInvalid={!!hostError}
            />
            <Form.Control.Feedback type="invalid">
              {hostError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer css={modalFooterStyle}>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectSettings;
