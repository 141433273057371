export const CARD_LIMITS = {
  switch: 1,
  lights: 2,
  button: 7,
  hvac: 5,
  basicLight: 10,
  basicLightWithFeedback: 1,
  dimmableLight: 1,
  rgbLight: 1,
  rgbLightWithBrightness: 1
};