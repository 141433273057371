/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { Form, Button, Modal } from 'react-bootstrap';
import { FaTrashAlt, FaEdit, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { css } from '@emotion/react';
import { useDtpOptions } from '../contexts/DtpOptionsContext';
import { cardStyle, listGroupItemStyle } from './styles';

const HVACCard = ({
  onDrop,
  configurations,
  sourceId,
  onRemove,
  handleCardDoneChange,
  isDone,
  updateConfigurations,
  moveEntityToGroupAddresses,
  GROUP_ADDRESS_ID,
  cardName,
  updateCardName,
  cardType,
  setGroupAddresses 
}) => {
  const dtpOptions = useDtpOptions();
  const allDtpOptions = dtpOptions.hvac || [];

  const [localConfigurations, setLocalConfigurations] = useState(configurations);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(cardName);
  const [isEditingName, setIsEditingName] = useState(false);
  const nameInputRef = useRef(null);
  const [previousName, setPreviousName] = useState(cardName);
  const [isCollapsed, setIsCollapsed] = useState(isDone);

  useEffect(() => {
    setLocalConfigurations(configurations);
  }, [configurations]);

  useEffect(() => {
    setIsCollapsed(isDone);
  }, [isDone]);

  const handleDrop = useCallback((item, targetDtpIndex) => {
    if (isDone) return;
    
    setLocalConfigurations(prev => {
      const isOccupied = prev.some(config => config.dtpIndex === targetDtpIndex);
      if (isOccupied) return prev;

      const newConfig = { ...item, dtpIndex: targetDtpIndex, dtp: allDtpOptions[targetDtpIndex].name };
      const updatedConfigurations = [...prev.filter(config => config.dtpIndex !== targetDtpIndex), newConfig];
      updateConfigurations(sourceId, updatedConfigurations);
      return updatedConfigurations;
    });

    setGroupAddresses((prev) => ({
      ...prev,
      addresses: prev.addresses.filter(address => address.id !== item.id)
    }));

    onDrop(item, GROUP_ADDRESS_ID);
  }, [isDone, sourceId, updateConfigurations, setGroupAddresses, onDrop, GROUP_ADDRESS_ID, allDtpOptions]);

  const handleRemoveEntity = useCallback((entityId) => {
    if (isDone) return;

    setLocalConfigurations(prev => {
      const entityToRemove = prev.find(config => config.id === entityId);
      const updated = prev.filter(config => config.id !== entityId);
      
      updateConfigurations(sourceId, updated);
      
      if (entityToRemove) {
        moveEntityToGroupAddresses(entityToRemove);
      }
      
      return updated;
    });
  }, [isDone, sourceId, updateConfigurations, moveEntityToGroupAddresses]);

  const handleDoneChange = useCallback(() => {
    const newDoneStatus = !isDone;
    handleCardDoneChange(newDoneStatus);
    setIsCollapsed(newDoneStatus);
  }, [isDone, handleCardDoneChange]);

  const handleRemoveCard = () => setShowModal(true);

  const confirmRemoveCard = () => {
    onRemove(sourceId);
    setShowModal(false);
  };

  const cancelRemoveCard = () => setShowModal(false);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(newName) && newName.length <= 20) {
      setName(newName);
    }
  };

  const toggleEditingName = () => {
    if (isEditingName) {
      if (name.length < 3) {
        setName(previousName);
      } else {
        setPreviousName(name);
        updateCardName(sourceId, name);
      }
    } else {
      setPreviousName(name);
    }
    setIsEditingName((prev) => !prev);
  };

  useEffect(() => {
    if (isEditingName && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isEditingName]);

  const isDoneDisabled = () => {
    const isNameValid = name.length >= 3;
    const allDtpFilled = allDtpOptions.every((_, index) => 
      localConfigurations.some(config => config.dtpIndex === index)
    );
    return !isNameValid || !allDtpFilled;
  };

  const topBarStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ddd;
  `;

  const expandButtonStyle = css`
    color: #007bff;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 123, 255, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    }
  `;

  const dropAreaStyle = css`
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
  `;

  const dropAreaHoverStyle = css`
    background-color: #e0f7fa;
    border-color: #007bff;
  `;

  const mandatoryEmptyStyle = css`
    border-color: #dc3545;
  `;

  const entityStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f1f3f5;
    }
  `;

  const DropArea = ({ dtpOption, index }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'groupAddress',
      drop: (item) => handleDrop(item, index),
      canDrop: () => !localConfigurations.some(config => config.dtpIndex === index),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }), [localConfigurations, handleDrop]);

    const associatedEntity = localConfigurations.find(config => config.dtpIndex === index);

    return (
      <div
        ref={drop}
        css={[
          dropAreaStyle,
          !associatedEntity && mandatoryEmptyStyle,
          isOver && dropAreaHoverStyle,
          css`
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: ${isOver ? 'rgba(0, 123, 255, 0.1)' : 'transparent'};
              pointer-events: none;
              transition: background-color 0.3s ease;
            }
          `
        ]}
      >
        <strong>{dtpOption.label} (Mandatory):</strong>
        {associatedEntity ? (
          <div css={entityStyle}>
            <span>{associatedEntity.name} ({associatedEntity.address})</span>
            <Button
              variant="link"
              size="sm"
              className="text-danger p-0 ml-2"
              onClick={() => handleRemoveEntity(associatedEntity.id)}
              disabled={isDone}
              css={css`font-size: 1.2rem; line-height: 1;`}
            >
              <FaTrashAlt />
            </Button>
          </div>
        ) : (
          <em>Drop an entity here</em>
        )}
      </div>
    );
  };

  return (
    <>
      <div css={[cardStyle, isDone && css`background-color: #28a745;`]}>
        <div css={topBarStyle}>
          <strong>{cardType}</strong>
          {isDone && (
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              css={expandButtonStyle}
              aria-label={isCollapsed ? "Expand card" : "Collapse card"}
            >
              {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          )}
        </div>
        <div css={css`padding: 1rem;`}>
          <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;`}>
            {isEditingName ? (
              <Form.Control 
                type="text" 
                placeholder="Enter card name" 
                value={name} 
                onChange={handleNameChange}
                ref={nameInputRef}
                onBlur={toggleEditingName}
                autoFocus
                css={css`
                  ${name.length < 3 ? 'box-shadow: 0 0 5px red;' : 'box-shadow: 0 0 5px blue;'}
                `}
              />
            ) : (
              <h5>{name.length > 0 ? name : <span css={css`color: red;`}>Nume Card</span>}</h5>
            )}
            <Button 
              variant="link" 
              size="sm" 
              className="text-secondary p-0 ml-2" 
              onClick={toggleEditingName}
              css={css`font-size: 1.2rem; line-height: 1;`}
              disabled={isDone}
            >
              <FaEdit />
            </Button>
          </div>
          
          {!isCollapsed && (
            <>
              {allDtpOptions.map((option, index) => (
                <DropArea 
                  key={index}
                  dtpOption={option} 
                  index={index}
                />
              ))}
              
              <Button variant="danger" onClick={handleRemoveCard} css={css`margin-top: 0.5rem;`}>
                Remove
              </Button>
            </>
          )}
          
          <div css={css`display: flex; align-items: center; margin-top: 1rem;`}>
            <Form.Check
              type="checkbox"
              label="Done"
              checked={isDone}
              disabled={isDoneDisabled()}
              onChange={handleDoneChange}
              css={css`margin-right: 1rem;`}
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={cancelRemoveCard}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this HVAC card? All entities will be moved back to the group addresses.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelRemoveCard}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmRemoveCard}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HVACCard;