//components/ConfigurationManagement.js
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { saveConfigurations } from '../utils/api';

const ConfigurationManagement = ({
  currentProjectId,
  groupAddresses,
  dynamicCards,
  token,
  autosaveEnabled,
  setAutosaveEnabled,
  handleSaveConfigurations, // Added handleSaveConfigurations prop
  disabled // Added disabled prop
}) => {
  useEffect(() => {
    let autosaveInterval;
    if (autosaveEnabled) {
      autosaveInterval = setInterval(() => {
        handleSaveConfigurations();
      }, 300000); // 5 minutes
    }
    return () => clearInterval(autosaveInterval);
  }, [autosaveEnabled]);

  useEffect(() => {
    localStorage.setItem('autosaveEnabled', JSON.stringify(autosaveEnabled));
  }, [autosaveEnabled]);

  return (
    <div>
      <button
        css={css`
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          &:hover {
            background-color: #0056b3;
          }
          &:disabled {
            background-color: #a0c4ff;
            cursor: not-allowed;
          }
        `}
        onClick={handleSaveConfigurations}
        disabled={disabled}
      >
        Salveaza configuratia
      </button>
    </div>
  );
};

export default ConfigurationManagement;
