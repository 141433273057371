import React, { createContext, useContext, useState, useEffect } from 'react';
import { CARD_LIMITS } from '../constants/cardLimits';

const CardLimitsContext = createContext();

export function CardLimitsProvider({ children }) {
  const [cardLimits, setCardLimits] = useState(CARD_LIMITS);

  useEffect(() => {
    setCardLimits(CARD_LIMITS);
  }, []);

  return (
    <CardLimitsContext.Provider value={{ cardLimits }}>
      {children}
    </CardLimitsContext.Provider>
  );
}

export function useCardLimits() {
  return useContext(CardLimitsContext);
}