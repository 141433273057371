/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

// General container styles
export const containerStyle = css`
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

// Header styles
export const headerStyle = css`
  margin-bottom: 1.5rem;
  text-align: center;
`;

// Title styles
export const titleStyle = css`
  font-size: 2rem;
  font-weight: bold;
`;

// Form styles
export const formStyle = css`
  display: flex;
  flex-direction: column;
`;

// Button styles
export const buttonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

// Dropdown button styles
export const dropdownButtonStyle = css`
  margin: 10px;
`;

// User dropdown styles
export const userDropdownStyle = css`
  .dropdown-toggle {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .dropdown-menu {
    right: 0;
    left: auto;
  }
`;

// Card styles
export const cardStyle = css`
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background: #fff;
`;

export const cardTitleStyle = css`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

export const cardBodyStyle = css`
  padding: 0.75rem;
`;

// List group item styles
export const listGroupItemStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 0.5rem;
`;

// Form group styles
export const formGroupStyle = css`
  margin-bottom: 1rem;
`;

// Label styles
export const labelStyle = css`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

// Input styles
export const inputStyle = css`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

// Checkbox styles
export const checkboxStyle = css`
  margin-right: 0.5rem;
`;

// Modal styles
export const modalContentStyle = css`
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const modalHeaderStyle = css`
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
`;

export const modalFooterStyle = css`
  border-top: 1px solid #ddd;
  margin-top: 1rem;
`;

// Remove button styles
export const removeButtonStyle = css`
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
`;

// Error text styles
export const errorTextStyle = css`
  color: #dc3545;
  margin-top: 0.5rem;
`;

// Alert styles
export const alertStyle = css`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #dc3545;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
`;

// Cards area styles
export const cardsAreaStyle = css`
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #e0e0e0;
`;

// Card container styles with conditional border color
export const cardContainerStyle = css`
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background: #fff;

  &.is-over {
    border-color: #28a745; /* Green border when dragged over */
  }

  &.is-done {
    background-color: #28a745; /* Green background when done */
  }
`;
