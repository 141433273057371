/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Nav } from 'react-bootstrap';

const CardFilterTabs = ({ filter, setFilter, cardCounts }) => {
  return (
    <Nav
      variant="tabs"
      activeKey={filter}
      onSelect={(selectedKey) => setFilter(selectedKey)}
      className="mb-4"
      css={css`
        margin-bottom: 1rem;
        .nav-link {
          color: #007bff;
          &:hover {
            color: #0056b3;
          }
          &.active {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
          }
        }
      `}
    >
      <Nav.Item>
        <Nav.Link eventKey="all">All ({cardCounts.all})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="switch">Switch ({cardCounts.switch || 0})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="lights">Lights ({cardCounts.lights || 0})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="button">Button ({cardCounts.button || 0})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="hvac">HVAC ({cardCounts.hvac || 0})</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default CardFilterTabs;
