import React, { createContext, useContext } from 'react';
import { CARD_OPTIONS } from '../constants/cardOptions';

const CardOptionsContext = createContext(CARD_OPTIONS);

export const useCardOptions = () => useContext(CardOptionsContext);

export const CardOptionsProvider = ({ children }) => {
  return (
    <CardOptionsContext.Provider value={CARD_OPTIONS}>
      {children}
    </CardOptionsContext.Provider>
  );
};