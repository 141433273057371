/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

const FileUpload = ({ onFileParsed, disabled }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log('File selected:', selectedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    console.log('Starting file upload...');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/config/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      });

      console.log('File upload response:', response);

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Received Data from Backend:', result);

      if (!result.groupAddresses) {
        console.error('No groupAddresses in response');
        return;
      }

      onFileParsed(result.groupAddresses);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div css={containerStyle}>
      <input type="file" onChange={handleFileChange} disabled={disabled} css={inputStyle} />
      <button onClick={handleFileUpload} disabled={disabled} css={buttonStyle}>Upload CSV</button>
    </div>
  );
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const inputStyle = css`
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  &:disabled {
    border-color: #a0c4ff;
    cursor: not-allowed;
  }
`;

const buttonStyle = css`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #a0c4ff;
    cursor: not-allowed;
  }
`;

export default FileUpload;
