/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Alert, Modal, Form, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { useDtpOptions } from './contexts/DtpOptionsContext';
import { useUser } from './contexts/UserContext';
import UserDropdown from './components/UserDropdown';
import FileUpload from './components/FileUpload';
import ProjectManagement from './components/ProjectManagement';
import ConfigurationManagement from './components/ConfigurationManagement';
import CardManagement from './components/CardManagement';
import ProjectSettings from './components/ProjectSettings';
import { fetchProjects, fetchConfiguration, saveConfigurations, deleteProject, updateProjectSettings, createProject, fetchUserSettings, updateUserSettings } from './utils/api';
import { cardsAreaStyle } from './components/styles';
import { saveAs } from 'file-saver'; // Import file-saver

const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const dtpOptions = useDtpOptions();
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');

  const [groupAddresses, setGroupAddresses] = useState({ id: uuidv4(), addresses: [] });
  const [dynamicCards, setDynamicCards] = useState([]);
  const [doneCards, setDoneCards] = useState(0);
  const [filter, setFilter] = useState('all');

  const [autosaveEnabled, setAutosaveEnabled] = useState(false);
  const [projects, setProjects] = useState([]);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState('');
  const [currentProjectHost, setCurrentProjectHost] = useState('');
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserSettings(token)
        .then((settings) => {
          setAutosaveEnabled(settings.autosave);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            navigate('/login');
          } else {
            console.error('Error fetching user settings:', error);
          }
        });
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchProjects(token)
        .then((data) => {
          console.log('Projects fetched from API:', data);
          setProjects(data);
        })
        .catch((error) => toast.error('Error loading projects'));
    }
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      console.log(`Fetching configuration for project ID: ${currentProjectId}`);
      fetchConfiguration(currentProjectId, localStorage.getItem('token'))
        .then((data) => {
          console.log(`Configuration loaded for project ID: ${currentProjectId}`, data);
          setGroupAddresses(data.groupAddresses || { id: uuidv4(), addresses: [] });
          setDynamicCards(data.dynamicCards || []);
          setCurrentProjectHost(data.host || '');
        })
        .catch((error) => {
          if (error.message === '401') {
            localStorage.removeItem('token');
            navigate('/login');
          } else {
            toast.error('Error loading configuration');
          }
        });
    }
  }, [currentProjectId, navigate]);

  const handleSaveConfigurations = () => {
    if (!currentProjectId) return;

    const configurations = {
      groupAddresses,
      dynamicCards
    };

    return saveConfigurations(currentProjectId, configurations, localStorage.getItem('token'))
      .then((data) => toast.success('Configurations saved successfully'))
      .catch((error) => {
        if (error.message === '401') {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          toast.error('Error saving configurations: ' + error.message);
        }
      });
  };

  const handleExportYAML = async () => {
    if (!currentProjectId) return;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/config/export?projectId=${currentProjectId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to export YAML configuration');
      }
  
      const result = await response.json();
      toast.success('YAML configuration exported successfully');
      console.log('YAML file saved at:', result.filePath);
    } catch (error) {
      console.error('Error exporting YAML configuration:', error);
      toast.error('Error exporting YAML configuration');
    }
  };
  

  const handleLogout = async () => {
    await handleSaveConfigurations();
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDeleteProject = async () => {
    if (!currentProjectId) return;

    try {
      await deleteProject(currentProjectId, localStorage.getItem('token'));
      setProjects((prev) => prev.filter((project) => project.id !== currentProjectId));
      setCurrentProjectId(null);
      setCurrentProjectName('');
      setCurrentProjectHost('');
      setGroupAddresses({ id: uuidv4(), addresses: [] });
      setDynamicCards([]);
      setShowDeleteAlert(false);
      toast.success('Project deleted successfully');
    } catch (error) {
      if (error.message === '401') {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        toast.error('Error deleting project');
      }
    }
  };

  const handleUpdateProjectSettings = async (updatedName, updatedHost) => {
    console.log('Updating project settings:', { updatedName, updatedHost });
    try {
      await updateProjectSettings(currentProjectId, { name: updatedName, host: updatedHost }, localStorage.getItem('token'));
      setProjects((prev) =>
        prev.map((project) =>
          project.id === currentProjectId ? { ...project, name: updatedName, host: updatedHost } : project
        )
      );
      setCurrentProjectName(updatedName);
      setCurrentProjectHost(updatedHost);
      setShowSettingsModal(false);
      toast.success('Project settings updated successfully');
    } catch (error) {
      if (error.message === '401') {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Error updating project settings:', error);
        toast.error('Error updating project settings');
      }
    }
  };

  const handleCreateProject = async () => {
    const token = localStorage.getItem('token');
    try {
      const project = await createProject(newProjectName, token);
      setProjects((prev) => [...prev, project]);
      setCurrentProjectId(project.id);
      setCurrentProjectName(project.name);
      setGroupAddresses({ id: uuidv4(), addresses: [] });
      setDynamicCards([]);
      setShowProjectModal(false);
      setNewProjectName('');
      console.log(`New project created: ${project.name}`);
    } catch (error) {
      if (error.message === '401') {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        toast.error('Error creating project');
      }
    }
  };

  const handleAutosaveToggle = async (enabled) => {
    setAutosaveEnabled(enabled);
    const token = localStorage.getItem('token');
    try {
      await updateUserSettings({ autosave: enabled }, token);
    } catch (error) {
      if (error.message === '401') {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        toast.error('Error updating user settings: ' + error.message);
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container css={css`overflow-y: hidden;`}>
        <ToastContainer />
        <Row className="justify-content-between align-items-center">
          <Col>
            <h2 css={css`text-align: center; margin: 1rem 0;`}>KNX - HAI</h2>
          </Col>
          <Col css={css`display: flex; justify-content: flex-end;`}>
            <UserDropdown
              autosaveEnabled={autosaveEnabled}
              setAutosaveEnabled={handleAutosaveToggle}
              handleLogout={handleLogout}
            />
          </Col>
        </Row>
        <Row css={css`margin-bottom: 1rem;`}>
          <Col>
            <Card css={css`
              background-color: white;
              border-radius: 0.5rem;
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
              padding: 1rem;
            `}>
              <Card.Body css={css`display: flex; justify-content: space-between; align-items: center;`}>
                <div>
                  <Card.Title>Upload CSV File</Card.Title>
                  <FileUpload
                    onFileParsed={(data) => {
                      const parsedAddresses = data.map((item) => ({
                        id: uuidv4(),
                        name: item.name,
                        address: item.address,
                        entity: item.entity
                      }));
                      console.log('Parsed Addresses:', parsedAddresses);
                      setGroupAddresses((prev) => ({ ...prev, addresses: parsedAddresses }));
                    }}
                    disabled={!currentProjectId}
                  />
                </div>
                <div css={css`display: flex;`}>
                  <ConfigurationManagement
                    currentProjectId={currentProjectId}
                    groupAddresses={groupAddresses}
                    dynamicCards={dynamicCards}
                    token={localStorage.getItem('token')}
                    handleSaveConfigurations={handleSaveConfigurations}
                    disabled={!currentProjectId}
                  />
                  <Button variant="primary" onClick={handleExportYAML} css={css`margin-left: 1rem;`}>
                    Export YAML
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row css={css`margin-bottom: 1rem; display: flex; align-items: center;`}>
          <Col css={css`display: flex; align-items: center;`}>
            <ProjectManagement
              projects={projects}
              setProjects={setProjects}
              currentProjectId={currentProjectId}
              setCurrentProjectId={setCurrentProjectId}
              setCurrentProjectName={setCurrentProjectName}
              setCurrentProjectHost={setCurrentProjectHost}
              token={localStorage.getItem('token')}
              setGroupAddresses={setGroupAddresses}
              setDynamicCards={setDynamicCards}
            />
            <Button variant="primary" onClick={() => setShowProjectModal(true)} css={css`margin: 0 1rem;`}>
              New Project
            </Button>
            {currentProjectId && (
              <>
                <Button variant="info" onClick={() => setShowSettingsModal(true)} css={css`margin: 0 1rem;`}>
                  Project Settings
                </Button>
              </>
            )}
          </Col>
          <Col css={css`text-align: right;`}>
            {currentProjectId && (
              <Button variant="danger" onClick={() => setShowDeleteAlert(true)}>
                Delete Project
              </Button>
            )}
          </Col>
        </Row>
        {showDeleteAlert && (
          <Row>
            <Col>
              <Alert variant="danger" onClose={() => setShowDeleteAlert(false)} dismissible>
                <Alert.Heading>Are you sure you want to delete the project?</Alert.Heading>
                <p>
                  Entire configuration of the project <strong>{currentProjectName}</strong> will be deleted.
                </p>
                <Button onClick={handleDeleteProject} variant="danger">Yes, delete it</Button>
              </Alert>
            </Col>
          </Row>
        )}
        {showSettingsModal && (
          <ProjectSettings
            show={showSettingsModal}
            onHide={() => setShowSettingsModal(false)}
            projectName={currentProjectName}
            projectHost={currentProjectHost}
            onSave={handleUpdateProjectSettings}
          />
        )}
        <Modal show={showProjectModal} onHide={() => setShowProjectModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowProjectModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateProject}>
              Create Project
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="cards-area" css={cardsAreaStyle}>
          <h4 css={css`text-align: left; margin-bottom: 1rem;`}>Current Project: {currentProjectName || 'None'}</h4>
          <CardManagement
            groupAddresses={groupAddresses}
            setGroupAddresses={setGroupAddresses}
            dynamicCards={dynamicCards}
            setDynamicCards={setDynamicCards}
            dtpOptions={dtpOptions}
            filter={filter}
            setFilter={setFilter}
            doneCards={doneCards}
            setDoneCards={setDoneCards}
            disableAddCard={!currentProjectId}
          />
        </div>
      </Container>
    </DndProvider>
  );
};

export default HomePage;
