import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { cardStyle } from './styles';

const CardContainer = styled.div`
  ${cardStyle}
`;

const RGBLightWithBrightnessCard = ({ onRemove }) => {
  const [name, setName] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [color, setColor] = useState('');
  const [brightness, setBrightness] = useState('');

  const handleNameChange = (e) => setName(e.target.value);
  const handleDoneChange = () => setIsDone(!isDone);
  const handleColorChange = (e) => setColor(e.target.value);
  const handleBrightnessChange = (e) => setBrightness(e.target.value);

  return (
    <CardContainer>
      <Form.Group controlId="rgb-light-brightness-name">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          type="text" 
          value={name} 
          onChange={handleNameChange} 
          placeholder="Enter light name" 
        />
      </Form.Group>
      <Form.Group controlId="rgb-light-brightness-color">
        <Form.Label>Color Control</Form.Label>
        <Form.Control 
          type="text" 
          value={color} 
          onChange={handleColorChange} 
          placeholder="Enter color control address" 
        />
      </Form.Group>
      <Form.Group controlId="rgb-light-brightness">
        <Form.Label>Brightness Control</Form.Label>
        <Form.Control 
          type="text" 
          value={brightness} 
          onChange={handleBrightnessChange} 
          placeholder="Enter brightness control address" 
        />
      </Form.Group>
      <Form.Group controlId="rgb-light-brightness-done">
        <Form.Check 
          type="checkbox" 
          label="Done" 
          checked={isDone} 
          onChange={handleDoneChange} 
        />
      </Form.Group>
      <Button variant="danger" onClick={onRemove}>Remove</Button>
    </CardContainer>
  );
};

export default RGBLightWithBrightnessCard;
