/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Dropdown, Form, ButtonGroup } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import ResetPasswordModal from './ResetPasswordModal';
import { userDropdownStyle } from './styles'; // Import the userDropdownStyle

const UserDropdown = ({ autosaveEnabled, setAutosaveEnabled, handleLogout }) => {
  const { user } = useAuth();
  const [showResetModal, setShowResetModal] = useState(false);

  const handleShowResetModal = () => setShowResetModal(true);
  const handleHideResetModal = () => setShowResetModal(false);

  return (
    <>
      <Dropdown as={ButtonGroup} className="user-dropdown" css={userDropdownStyle}>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          {user && user.name ? user.name : 'User'}
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item as="div">
            <Form.Check
              type="switch"
              id="autosave-switch"
              label="Autosave"
              checked={autosaveEnabled}
              onChange={(e) => setAutosaveEnabled(e.target.checked)}
            />
          </Dropdown.Item>
          <Dropdown.Item onClick={handleShowResetModal}>Reset Password</Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ResetPasswordModal show={showResetModal} onHide={handleHideResetModal} token={localStorage.getItem('token')} />
    </>
  );
};

export default UserDropdown;
