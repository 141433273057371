/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import GroupAddressItem from './GroupAddressItem';

const GroupAddressList = ({ groupAddresses }) => {
  console.log('Group Addresses in List:', groupAddresses);
  return (
    <div css={listContainerStyle}>
      {groupAddresses.map((address) => (
        <div key={address.id} css={listItemStyle}>
          <GroupAddressItem address={address} />
        </div>
      ))}
    </div>
  );
};

const listContainerStyle = css`
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

const listItemStyle = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
`;

export default GroupAddressList;
