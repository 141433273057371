/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext'; // Import Auth Context
import {
  containerStyle,
  headerStyle,
  titleStyle,
  formStyle,
  buttonStyle
} from './styles'; // Import Emotion styles

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const { login } = useAuth(); // Get login from context

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      toast.error('Please fill in both fields');
      return;
    }
    setLoading(true); // Start loading
    const payload = { username, password };
    console.log('Login request payload:', payload); // Log the request payload

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();
      console.log('Login response:', data); // Log the response

      const tokenExpiry = new Date().getTime() + 60 * 60 * 1000; // 1 hour
      login({ name: username }, data.token, tokenExpiry);

      toast.success('Login successful');
    } catch (error) {
      console.error('Login failed:', error); // Log errors
      toast.error('Login failed: ' + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container css={containerStyle}>
      <div css={headerStyle}>
        <h1 css={titleStyle}>KNX - HAI</h1>
        <h2 className="text-center">Login</h2>
      </div>
      <Form onSubmit={handleLogin} css={formStyle}>
        <Form.Group controlId="formUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            aria-label="Username"
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
          />
        </Form.Group>

        <Button variant="primary" type="submit" css={buttonStyle} disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Login'}
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
