/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, DropdownButton, Dropdown, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Masonry from 'react-masonry-css';
import { css } from '@emotion/react';
import GroupAddressList from './GroupAddressList';
import SwitchCard from './SwitchCard';
import ButtonCard from './ButtonCard';
import HVACCard from './HVACCard';
import BasicLightCard from './BasicLightCard';
import DimmableLightCard from './DimmableLightCard';
import RGBLightCard from './RGBLightCard';
import RGBLightWithBrightnessCard from './RGBLightWithBrightnessCard';
import DragDropContainer from './DragDropContainer';
import CardFilterTabs from './CardFilterTabs';
import LightTypeSelectionModal from './LightTypeSelectionModal';
import { useCardLimits } from '../contexts/CardLimitsContext';
import { useCardOptions } from '../contexts/CardOptionsContext';
import { useDtpOptions } from '../contexts/DtpOptionsContext';

const dropdownContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const dropdownButtonStyle = css`
  margin-right: 1rem;
  flex-shrink: 0;
`;

const cardContainerStyle = css`
  margin-bottom: 1rem;
`;

const filterTabsContainerStyle = css`
  display: flex;
  justify-content: flex-end;
`;

const masonryStyle = css`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
`;

const masonryColumnStyle = css`
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
`;

const CardManagement = ({
  groupAddresses,
  setGroupAddresses,
  dynamicCards,
  setDynamicCards,
  filter,
  setFilter,
  doneCards,
  setDoneCards,
  disableAddCard
}) => {
  const { cardLimits } = useCardLimits();
  const cardOptions = useCardOptions();
  const dtpOptions = useDtpOptions();

  const [showLightModal, setShowLightModal] = useState(false);

  const GROUP_ADDRESS_ID = groupAddresses.id;

  useEffect(() => {
    console.log('CardManagement - Initial Context Values:');
    console.log('CardLimits:', cardLimits);
    console.log('CardOptions:', cardOptions);
    console.log('DtpOptions:', dtpOptions);
  }, [cardLimits, cardOptions, dtpOptions]);

  const handleAddCard = (cardType) => {
    console.log(`handleAddCard called with type: ${cardType}`);
    if (cardType === 'lights') {
      setShowLightModal(true);
    } else {
      const newCard = { id: uuidv4(), type: cardType, name: '', configurations: [], isDone: false, optionValues: {} };
      setDynamicCards((prev) => {
        console.log('Adding new card:', newCard);
        return [...prev, newCard];
      });
      toast.success(`${cardType === 'hvac' ? 'HVAC' : cardType} card added successfully`);
    }
  };

  const handleLightTypeSelect = (lightType) => {
    console.log(`handleLightTypeSelect called with type: ${lightType}`);
    const cardType = lightType;
    const newCard = { id: uuidv4(), type: cardType, name: '', configurations: [], isDone: false, optionValues: {} };
    setDynamicCards((prev) => {
      console.log('Adding new light card:', newCard);
      return [...prev, newCard];
    });
    toast.success(`${lightType} card added successfully`);
    setShowLightModal(false);
  };

  const handleDrop = (item, sourceId, targetId, targetType) => {
    console.log(`handleDrop called - sourceId: ${sourceId}, targetId: ${targetId}, targetType: ${targetType}`);
    if (sourceId === targetId) return;

    if (targetId === GROUP_ADDRESS_ID) {
      setGroupAddresses((prev) => {
        const isItemAlreadyPresent = prev.addresses.some((address) => address.id === item.id);
        if (isItemAlreadyPresent) {
          console.warn('Item already exists in group addresses');
          return prev;
        }
        const updatedAddresses = [...prev.addresses, item];
        removeItemFromSource(sourceId, item.id);
        console.log('Updated group addresses:', updatedAddresses);
        return { ...prev, addresses: updatedAddresses };
      });
      return;
    }

    setDynamicCards((prev) => {
      const targetCardIndex = prev.findIndex((card) => card.id === targetId);
      if (targetCardIndex === -1) return prev;

      const targetCard = prev[targetCardIndex];
      const limit = cardLimits[targetCard.type.toLowerCase()];
      if (limit !== undefined && targetCard.configurations.length >= limit) {
        console.log(`Limit reached for ${targetCard.type}`);
        toast.info(`Limit reached for ${targetCard.type}`);
        return prev;
      }

      const updatedCard = { ...targetCard, configurations: [...targetCard.configurations, item] };
      const updatedCards = [...prev.slice(0, targetCardIndex), updatedCard, ...prev.slice(targetCardIndex + 1)];

      removeItemFromSource(sourceId, item.id);
      console.log(`Dropped item into card ${targetCard.id}:`, updatedCard);
      return updatedCards;
    });
  };

  const removeItemFromSource = (sourceId, itemId) => {
    console.log(`removeItemFromSource called - sourceId: ${sourceId}, itemId: ${itemId}`);
    if (sourceId === GROUP_ADDRESS_ID) {
      setGroupAddresses((prev) => {
        const updatedAddresses = prev.addresses.filter((address) => address.id !== itemId);
        console.log('Removed item from group addresses:', updatedAddresses);
        return { ...prev, addresses: updatedAddresses };
      });
    } else {
      setDynamicCards((prev) => {
        const updatedCards = prev.map((card) => {
          if (card.id === sourceId) {
            const updatedConfigurations = card.configurations.filter((config) => config.id !== itemId);
            console.log(`Removed item from card ${card.id}:`, updatedConfigurations);
            return { ...card, configurations: updatedConfigurations };
          }
          return card;
        });
        return updatedCards;
      });
    }
  };

  const handleRemoveCard = (cardId) => {
    console.log(`handleRemoveCard called for cardId: ${cardId}`);
    setDynamicCards((prev) => {
      const cardToRemove = prev.find((card) => card.id === cardId);
      if (!cardToRemove) return prev;

      setGroupAddresses((prevGroupAddresses) => {
        // Create a Set of existing address IDs for quick lookup
        const existingIds = new Set(prevGroupAddresses.addresses.map(addr => addr.id));
        
        // Filter out duplicates and undefined dtp values
        const entitiesToAdd = cardToRemove.configurations.filter(
          config => config.id && !existingIds.has(config.id) && config.dtp !== undefined
        );
        
        const updatedAddresses = [...prevGroupAddresses.addresses, ...entitiesToAdd];
        console.log('Moved entities back to group addresses:', updatedAddresses);
        return { ...prevGroupAddresses, addresses: updatedAddresses };
      });

      const updatedCards = prev.filter((card) => card.id !== cardId);
      console.log('Updated dynamic cards after removal:', updatedCards);
      return updatedCards;
    });
    toast.info('Card removed and entities moved to Entitati KNX');
  };

  const updateDoneCardsCount = (cardId, isDone) => {
    console.log(`updateDoneCardsCount called - cardId: ${cardId}, isDone: ${isDone}`);
    setDynamicCards((prev) => {
      const updatedCards = prev.map((card) => {
        if (card.id === cardId) {
          return { ...card, isDone };
        }
        return card;
      });

      const doneCount = updatedCards.filter((card) => card.isDone).length;
      setDoneCards(doneCount);
      console.log(`Updated done cards count: ${doneCount}`);
      return updatedCards;
    });
  };

  const handleCardDoneChange = (cardId) => {
    console.log(`handleCardDoneChange called for cardId: ${cardId}`);
    setDynamicCards((prev) => {
      const updatedCards = prev.map((card) => {
        if (card.id === cardId) {
          const newIsDone = !card.isDone;
          console.log(`Card ${cardId} isDone changed to: ${newIsDone}`);
          return { ...card, isDone: newIsDone };
        }
        return card;
      });
      const doneCount = updatedCards.filter((card) => card.isDone).length;
      setDoneCards(doneCount);
      return updatedCards;
    });
  };

  const updateConfigurations = (cardId, newConfigurations, newOptionValues) => {
    console.log(`updateConfigurations called for cardId: ${cardId}`);
    console.log('New configurations:', newConfigurations);
    console.log('New option values:', newOptionValues);
    setDynamicCards((prev) => {
      const updatedCards = prev.map((card) => {
        if (card.id === cardId) {
          return { ...card, configurations: newConfigurations, optionValues: newOptionValues };
        }
        return card;
      });
      return updatedCards;
    });
  };

  const moveEntityToGroupAddresses = (entity) => {
    console.log('moveEntityToGroupAddresses called with entity:', entity);
    setGroupAddresses((prev) => {
      const updatedAddresses = [...prev.addresses, entity];
      console.log('Updated group addresses:', updatedAddresses);
      return { ...prev, addresses: updatedAddresses };
    });
  };

  const handleUpdateCardName = (cardId, newName) => {
    console.log(`handleUpdateCardName called - cardId: ${cardId}, newName: ${newName}`);
    setDynamicCards((prev) => {
      const updatedCards = prev.map((card) => {
        if (card.id === cardId) {
          return { ...card, name: newName };
        }
        return card;
      });
      return updatedCards;
    });
  };

  const getCardComponent = (cardType) => {
    console.log(`getCardComponent called for type: ${cardType}`);
    switch (cardType) {
      case 'switch': return SwitchCard;
      case 'button': return ButtonCard;
      case 'hvac': return HVACCard;
      case 'basicLight': return BasicLightCard;
      case 'dimmableLight': return DimmableLightCard;
      case 'rgbLight': return RGBLightCard;
      default:
        console.warn(`No component found for card type: ${cardType}`);
        return null;
    }
  };

  const filteredCards = filter === 'all' ? dynamicCards : dynamicCards.filter(card => card.type === filter);

  console.log('Filtered Cards:', filteredCards);

  const cardCounts = dynamicCards.reduce((acc, card) => {
    acc[card.type] = (acc[card.type] || 0) + 1;
    acc.all = dynamicCards.length;
    return acc;
  }, { all: dynamicCards.length });

  console.log('Card Counts:', cardCounts);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1
  };

  return (
    <>
      <Row className="align-items-center mb-4" css={dropdownContainerStyle}>
        <Col md="auto">
          <DropdownButton
            id="dropdown-basic-button"
            title="Add New Card"
            disabled={disableAddCard}
            css={dropdownButtonStyle}
          >
            <Dropdown.Item onClick={() => handleAddCard('switch')}>Switch Card</Dropdown.Item>
            <Dropdown.Item onClick={() => handleAddCard('lights')}>Lights Card</Dropdown.Item>
            <Dropdown.Item onClick={() => handleAddCard('button')}>Button Card</Dropdown.Item>
            <Dropdown.Item onClick={() => handleAddCard('hvac')}>HVAC Card</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col css={filterTabsContainerStyle}>
          <CardFilterTabs filter={filter} setFilter={setFilter} cardCounts={cardCounts} />
        </Col>
      </Row>

      <Row>
        <Col md={4} className="mb-4">
          <Card className="custom-card" css={cardContainerStyle}>
            <Card.Body className="group-addresses-container">
              <Card.Title>Entitati KNX ({groupAddresses.addresses.length})</Card.Title>
              <DragDropContainer onDrop={(item) => handleDrop(item, GROUP_ADDRESS_ID, GROUP_ADDRESS_ID, 'groupAddress')}>
                <GroupAddressList groupAddresses={groupAddresses.addresses} />
              </DragDropContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8} className="scrollable-card-container">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            css={masonryStyle}
          >
            {filteredCards.map((card) => {
              console.log(`Rendering card of type: ${card.type}`);
              const CardComponent = getCardComponent(card.type);
              if (!CardComponent) {
                console.warn(`No component found for card type: ${card.type}`);
                return null;
              }

              const cardLimit = cardLimits[card.type] || 1;
              const cardOptionsForType = cardOptions[card.type] || [];
              const dtpOptionsForType = dtpOptions[card.type] || [];

              console.log(`Card ${card.id} - Type: ${card.type}, Limit: ${cardLimit}`);
              console.log(`Card Options:`, cardOptionsForType);
              console.log(`DTP Options:`, dtpOptionsForType);

              return (
                <div key={card.id} css={masonryColumnStyle}>
                  <CardComponent
                    onDrop={(item) => handleDrop(item, GROUP_ADDRESS_ID, card.id, card.type)}
                    configurations={card.configurations}
                    sourceId={card.id}
                    cardLimit={cardLimit}
                    updateDoneCardsCount={updateDoneCardsCount}
                    onRemove={handleRemoveCard}
                    handleCardDoneChange={() => handleCardDoneChange(card.id)}
                    isDone={card.isDone}
                    updateConfigurations={updateConfigurations}
                    moveEntityToGroupAddresses={moveEntityToGroupAddresses}
                    GROUP_ADDRESS_ID={GROUP_ADDRESS_ID}
                    className="custom-card"
                    cardName={card.name}
                    updateCardName={handleUpdateCardName}
                    dtpOptions={dtpOptionsForType}
                    cardOptions={cardOptionsForType}
                    cardOptionValues={card.optionValues}
                    cardType={card.type}
                    setGroupAddresses={setGroupAddresses} 
                  />
                </div>
              );
            })}
          </Masonry>
        </Col>
      </Row>
  
      <LightTypeSelectionModal
        show={showLightModal}
        onHide={() => setShowLightModal(false)}
        onSelect={handleLightTypeSelect}
      />
    </>
  );
};

export default CardManagement;