export const DTP_OPTIONS = {
  switch: [
    { name: 'DPT 1.001', label: 'Switch Control' },
    { name: 'DPT 1.002', label: 'Switch State' }
  ],
  button: [
    { name: 'address', label: 'Button Control' }
  ],
  basicLight: [
    { name: 'DPT 1.001', label: 'Light Control' },
    { name: 'DPT 1.002', label: 'Light State' }
  ],
  dimmableLight: [
    { name: 'DPT 1.001', label: 'Light Control' },
    { name: 'DPT 1.002', label: 'Light State' },
    { name: 'DPT 5.001', label: 'Brightness Address' },
    { name: 'DPT 5.001', label: 'Brightness State Address' }
  ],
  rgbLight: [
    { name: 'DPT 1.001', label: 'Light Control' },
    { name: 'DPT 1.002', label: 'Light State' },
    { name: 'DPT 5.001', label: 'Brightness Address' },
    { name: 'DPT 5.001', label: 'Brightness State Address' },
    { name: 'DPT 251.600', label: 'RGBW Address' },
    { name: 'DPT 251.600', label: 'RGBW State Address' }
  ],

  hvac: [
    { name: 'DPT 9.001', label: 'Temperature Address' },
    { name: 'DPT 6.010', label: 'Setpoint Shift Address' },
    { name: 'DPT 6.010', label: 'Setpoint Shift State Address' },
    { name: 'DPT 9.001', label: 'Target Temperature State Address' },
    { name: 'DPT 20.102', label: 'Operation Mode Address' },
    { name: 'DPT 20.102', label: 'Operation Mode State Address' }
  ],
    lights: ['DPT 1.001', 'DPT 3.007', 'DPT 5.001'],
    // button: ['DPT 1.001', 'DPT 1.008', 'DPT 1.009'],
    // hvac: ['DPT 1.100', 'DPT 5.004', 'DPT 9.001']
    // basicLight: ['DPT 1.001'],

    // dimmableLight: ['DPT 1.001', 'DPT 3.007'],
    // rgbLight: ['DPT 1.001', 'DPT 232.600'],

  };