/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useDrag } from 'react-dnd';

const GroupAddressItem = ({ address }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'groupAddress',
    item: { id: address.id, name: address.name, address: address.address },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} css={[itemStyle, isDragging && draggingStyle]}>
      {address.address} ({address.name})
    </div>
  );
};

const itemStyle = css`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: move;
  transition: background-color 0.3s ease;
`;

const draggingStyle = css`
  background-color: #e0e0e0;
`;

export default GroupAddressItem;
