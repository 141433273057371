import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';

const LightTypeSelectionModal = ({ show, onHide, onSelect }) => {
  const lightTypes = [
    { display: 'Basic light', value: 'basicLight' },
    { display: 'Dimmable light', value: 'dimmableLight' },
    { display: 'RGB Light', value: 'rgbLight' }
  ];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Light Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {lightTypes.map((type, idx) => (
            <ListGroup.Item key={idx} action onClick={() => onSelect(type.value)}>
              {type.display}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LightTypeSelectionModal;