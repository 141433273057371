import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [tokenExpiry, setTokenExpiry] = useState(localStorage.getItem('tokenExpiry'));
  const navigate = useNavigate();

  const login = (userData, token, expiry) => {
    setUser(userData);
    setToken(token);
    setTokenExpiry(expiry);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiry', expiry);
    navigate('/');
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setTokenExpiry(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    navigate('/login');
  };

  useEffect(() => {
    if (token && tokenExpiry) {
      const expiryTime = new Date(tokenExpiry).getTime();
      const currentTime = new Date().getTime();
      if (currentTime > expiryTime) {
        logout();
      }
    }
  }, [token, tokenExpiry]);

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
