//utils/api.js
import { v4 as uuidv4 } from 'uuid';

export const fetchCardLimits = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/card-limits`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Error loading card limits');
  }
  return response.json();
};

export const fetchProjects = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Error loading projects');
  }
  return response.json();
};

export const fetchConfiguration = async (projectId, token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/config/load?projectId=${projectId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.status === 404) {
      // Return default configuration if not found
      return { groupAddresses: { id: uuidv4(), addresses: [] }, dynamicCards: [] };
    }
    if (!response.ok) {
      throw new Error('Error loading configuration');
    }
    return response.json();
  } catch (error) {
    throw new Error(error.message || 'Error loading configuration');
  }
};

export const saveConfigurations = async (projectId, configurations, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/config/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ projectId, configurations })
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Error saving configurations');
  }
  return response.json();
};

export const createProject = async (name, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ name })
  });
  if (!response.ok) {
    throw new Error('Error creating project');
  }
  return response.json();
};

export const deleteProject = async (projectId, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Error deleting project');
  }
};

export const updateProjectSettings = async (projectId, settings, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(settings)
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Error updating project settings');
  }
  return response.json();
};

export const fetchUserSettings = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-settings`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Error fetching user settings');
  }
  return response.json();
};

export const updateUserSettings = async (settings, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-settings`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(settings)
  });
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Error updating user settings');
  }
  return response.json();
};
