// src/App.js
import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import HomePage from './HomePage';
import Login from './components/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { DtpOptionsProvider } from './contexts/DtpOptionsContext';
import { CardLimitsProvider } from './contexts/CardLimitsContext';
import { CardOptionsProvider } from './contexts/CardOptionsContext';
import { AuthProvider } from './contexts/AuthContext';
import { NavigateProvider } from './contexts/NavigateContext';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <AuthProvider>
        <NavigateProvider>
          <UserProvider>
            <DtpOptionsProvider>
              <CardLimitsProvider>
                <CardOptionsProvider>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<ProtectedRoute element={HomePage} />} />
                  </Routes>
                </CardOptionsProvider>
              </CardLimitsProvider>
            </DtpOptionsProvider>
          </UserProvider>
        </NavigateProvider>
      </AuthProvider>
    </Router>
  </ThemeProvider>
);

export default App;